.header {
  margin-top: -10px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 16px;
}

.headerTitleLg {
  position: absolute;
  top: 20%;
  font-size: 100px;
}

.headerImg{
    width: 100%;
    height: 390px;
    object-fit: cover;
}