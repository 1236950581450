*{
    box-sizing: border-box;
}
.contact {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.text-wrapper{
    width: 600px;
    text-align: center;
    background-color:#17a2b8;
    color: #f1f1f1;
    padding: 40px 50px;
    text-align: left;
}
.text-heading{
    font-size: 42px;
    margin-bottom: 1.5rem;
    font-weight:bolder;
}
.text-para{
    font-size: 18px;
    padding: 10px;
    line-height: 1.25;
    letter-spacing: 1.05px;
}
.phone {
    font-weight: bold;
}
.contact-wrapper {
    width: 400px;
    padding: 2rem;
}
.contact-wrapper h1 {
    font-size: 28px;
    text-align: left;
    margin-bottom: 2rem;
}
.form-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-wrapper form {
    width: 100%;
}
.form-item {
    width: 100%;
    margin-bottom: 18px;
}
.form-item label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    color: #6c757d;
}
.form-input,
.form-textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 14px 20px;
    border: 1px solid #6c757d;
    outline: none;
    font-size: 18px;
    font-family: inherit;
    border-radius: 4px;
    outline-color: #17a2b8;
    flex: 1;
}
.form-textarea {
    resize: none;
}
.form-input::placeholder,
.form-textarea::placeholder {
    font-family: inherit;
}

.form-btn {
    background-color:#17a2b8;
    padding: 14px 20px;
    width: 100%;
    border-radius: 4px;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-family: inherit;
}