.sidebar {
  flex: 3;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Josefin Sans", sans-serif;
}

.sidebarTitle {
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: 1px solid #a7a4a4;
  border-bottom: 1px solid #a7a4a4;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.sidebarItem > img {
  width: 100%;
  margin-top: 15px;
  border-radius: 50%;
  margin-bottom: 18px;
}
.sidebarItem > h3 {
  font-size: 22px;
}
.sidebarItem > p {
  padding: 15px 30px;
  text-align: center;
  line-height: 1.5;
}

.sidebarList {
  list-style: none;
  margin-bottom: 30px;
}

.sidebarListItem {
  display: inline-block;
  width: 50%;
  margin-top: 15px;
  cursor: pointer;
}

.sidebarSocial {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarIconWrapper {
  text-decoration: none;
  color: #333;
}

.sidebarIcon{
    font-size: 25px;
    margin-left: 10px;
    cursor: pointer;
}